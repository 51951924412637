div.section-bottom-1 {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  gap: 0px;
}

.bottom-categories {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: 15vw;
  position: relative;
  left: 0px;
  flex-wrap: wrap;
  row-gap: 20px;
}

div.bottom-category h1 {
  margin-bottom: 7px;
}

div.bottom-category a {
  display: block;
  line-height: 25px;
  text-decoration-line: underline;
}

.copyright {
  justify-self: right;
  text-align: right;
  padding-bottom: 20px;
}
