input.code-number {
  width: 68px;
  height: 72px;
  text-align: center;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  border-radius: 10px;
  background: #ede8db;
  border: none;
  color: rgba(77, 65, 63, 0.93);
  caret-color: transparent;
}

input.code-number:focus {
  border: 5px solid rgba(105, 40, 24, 0.11);
  outline: none;
}

input.invalid {
  border: 5px solid #ff7878;
  outline: none;
}

input.invalid:focus {
  border: 5px solid #fabe7f;
  outline: none;
}

/*
TODO: make caret blink when user is typing
 */
