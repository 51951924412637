.home-neil {
  align-items: flex-start;
  background-color: var(--emperor);
  border: 1px none;
  display: flex;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.overlap-group10 {
  height: 100%;
  margin-top: -31px;
  position: relative;
  width: 100%;
}

.cream-wave-1 {
  position: absolute;
  top: 838px;
  width: 100%;
}

.cream-square-2 {
  background-color: var(--sazerac);
  height: 625px;
  left: 21px;
  position: absolute;
  top: 970px;
  width: 1993px;
}

.headline {
  height: 430px;
  margin-right: 4px;
  position: relative;
  width: 1234px;
}

.stars {
  align-items: center;
  display: flex;
  gap: 60em;
  height: 300px;
  justify-content: center;
  min-width: 1234px;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
  padding: 0px 30px;
}

.stars-left {
  height: 248px;
  position: relative;
  width: 225px;
}

.overlap-group2-8 {
  height: 240px;
  left: 70px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.line-container-6 {
  height: 56px;
  left: -30px;
  position: absolute;
  top: 140px;
  width: 56px;
}

.line-6-4 {
  height: 50px;
  left: 13px;
  position: absolute;
  top: 2px;
  width: 31px;
}

.line-1-6 {
  height: 56px;
  left: 27px;
  position: absolute;
  top: 0;
  width: 3px;
}

.line-2-6 {
  height: 3px;
  left: 0;
  position: absolute;
  top: 25px;
  width: 56px;
}

.line-3-1 {
  height: 31px;
  left: 2px;
  position: absolute;
  top: 11px;
  width: 50px;
}

.line-5-5 {
  height: 50px;
  left: 12px;
  position: absolute;
  top: 3px;
  width: 31px;
}

.line-4-1 {
  height: 31px;
  left: 4px;
  position: absolute;
  top: 11px;
  width: 50px;
}

.star-22-2 {
  height: 205px;
  left: 28px;
  position: absolute;
  top: 0;
  width: 145px;
}

.star-23-1 {
  height: 107px;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 78px;
}

.stars-right {
  align-self: flex-end;
  height: 185px;
  position: relative;
  width: 153px;
}

.star-24-2 {
  height: 151px;
  left: 0;
  position: absolute;
  top: 34px;
  width: 112px;
}

.line-container-7 {
  height: 41px;
  left: 112px;
  position: absolute;
  top: 0;
  width: 41px;
}

.headline-text {
  text-align: center;
  margin-top: 20px;
  width: 70%;
  letter-spacing: -2.5px;
  line-height: 89px;
}

#unlock-the-potential {
  font-size: clamp(45px, calc(4vw + 15px), 65px);
  line-height: clamp(60px, calc(6vw + 26px), 89px);
}

.home .subheading-text {
  letter-spacing: 0;
  text-align: center;
  margin-top: 45px;
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}

#automated-short-form {
  padding: 0px 10%;
  font-size: clamp(15px, 1.5vw, 17px);
  line-height: 25px;
}

.overlap-group7-2 {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
}

.ad-magic-1 {
  line-height: 70px;
  top: 0;
  white-space: nowrap;
  color: var(--orchid-white);
  font-family: var(--font-family-montserrat);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

#ad-magic-logo {
  font-size: clamp(18px, 2.3vw, 22px);
}

.icon-label-1 {
  height: 34px;
  display: block;
  margin: auto;
}

.overlap-group6-2 {
  align-items: flex-end;
  background-color: var(--buttery-white);
  border-radius: 50px;
  display: flex;
  height: 54px;
  margin-left: 26.59px;
  margin-top: 19px;
  min-width: 421px;
  padding: 10.6px 28px;
}

.go-2 {
  height: 68px;
  margin-left: 27.59px;
  margin-top: 0px;
  width: 286px;
}

.go-button {
  height: 60px;
  margin-left: 0px;
  margin-top: 10px;
  width: fit-content;
  padding: 0 50px;
  background-color: #3a2b19;
  border-radius: 50px;
  border: none;
  color: white;
  transition: all 0.3s ease-in-out;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}

.go-button:hover {
  background-color: #3a2b19;
  border-radius: 50px;
  box-shadow: 0 0 10px #b65840;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.early-access {
  height: 60px;
  margin-left: 0px;
  margin-bottom: 50px;
  width: fit-content;
  padding: 0 50px;
  background: linear-gradient(90deg, #b65840 0%, #f9b384 100%);
  border-radius: 50px;
  border: none;
  color: white;
  transition: all 0.3s ease-in-out;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}

.early-access:hover {
    border-radius: 50px;
    box-shadow: 0 0 10px #b65840;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.go-button:disabled {
  background-color: rgba(58, 43, 25, 0.31);
  border-radius: 50px;
  box-shadow: none;
  cursor: not-allowed;
}

.login {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 20px 10px 0px 0;
}

p.login-text {
  letter-spacing: 0;
  line-height: 30px;
  font-size: 14px;
  padding: 0px 30px;
}

a.early-access-text {
  letter-spacing: 0;
  line-height: 30px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 70px;
  color: #C85B3D;

}

.login-pic {
  height: 50px;
  margin-bottom: -7.96px;
  width: 105px;
}

.second-section {
  height: auto;
  position: relative;
  width: 100%;
  background-color: #fff6e3;
  overflow: wrap;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 70px;
  padding-top: 20px;
}

.heart-bullets-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  position: relative;
  z-index: 1;
  padding-top: 12px;
}

.our-comprehensive-da-1 {
  letter-spacing: -1px;
  line-height: 40px;
  position: relative;
  top: 20px;
  width: 1153px;
  text-align: left;
  z-index: 1;
  padding-bottom: 30px;
  padding-top: 10px;
}

.learn-your-audience-1 {
  letter-spacing: -2px;
  line-height: 65px;
  padding-top: 10px;
  padding-bottom: 20px;
  position: relative;
  top: 20px;
  text-align: left;
  width: 1153px;
  z-index: 1;
}

#our-comprehensive {
  font-size: 20px;
}

.graphic-1 {
  align-items: center;
  background-color: var(--cognac);
  border-radius: 50px;
  display: flex;
  height: 260px;
  justify-content: flex-end;
  min-width: 558px;
  padding: 15.1px 10.4px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.flex-col-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-bottom: 9.73px;
  min-height: 80px;
  width: 5px;
}

.ellipse-8-1 {
  background-color: var(--soft-amber);
  border-radius: 2.39px/2.65px;
  height: 5px;
  width: 5px;
}

.rectangle-142-1 {
  background-color: var(--black-4);
  border-radius: 31px;
  height: 66px;
  width: 5px;
}

.overlap-group-20 {
  align-items: flex-start;
  align-self: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 229px;
  justify-content: flex-end;
  margin-left: 14px;
  min-width: 471px;
  padding: 78.6px 204.1px;
}

.polygon-1-1 {
  height: 51px;
  width: 42px;
}

.ellipse-9-1 {
  background-color: var(--soft-amber);
  border-radius: 13.97px/15.48px;
  height: 31px;
  margin-bottom: 0.88px;
  margin-left: 9px;
  width: 28px;
}

.stars-3 {
  align-items: flex-start;
  display: flex;
  height: 298px;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 0;
}

.overlap-group4-7 {
  height: 236px;
  position: relative;
  width: 160px;
}

.line-container-8 {
  height: 65px;
  left: 95px;
  position: absolute;
  top: 0;
  width: 65px;
}

.star-22-3 {
  height: 202px;
  left: 0;
  position: absolute;
  top: 34px;
  width: 112px;
}

.line-container-9 {
  align-self: center;
  height: 32px;
  margin-left: -30px;
  margin-top: 90px;
  position: absolute;
  width: 32px;
}

.overlap-group3-9 {
  align-self: flex-end;
  height: 238px;
  right: 0;
  position: absolute;
  width: 115px;
}

.section-middle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.section-middle-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-left: 200px;
  padding-right: 200px;
  width: 100%;
}

.section-middle-graphic {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-right: 50px;
  padding-left: 50px;
  gap: 30px;
}

.phone-graphic {
  width: 100%;
  max-width: 650px;
  margin-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 25px;
}

.star-24-3 {
  height: 185px;
  right: 0px;
  position: absolute;
  top: 60px;
  width: 80px;
}

.line-container-10 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.line-6-8 {
  height: 53px;
  left: 14px;
  position: absolute;
  top: 2px;
  width: 33px;
}

.line-1-10 {
  height: 60px;
  left: 29px;
  position: absolute;
  top: 0;
  width: 3px;
}

.line-2-10 {
  height: 3px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 60px;
}

.line-3-5 {
  height: 33px;
  left: 3px;
  position: absolute;
  top: 12px;
  width: 53px;
}

.line-5-9 {
  height: 53px;
  left: 13px;
  position: absolute;
  top: 3px;
  width: 33px;
}

.line-4-5 {
  height: 33px;
  left: 4px;
  position: absolute;
  top: 12px;
  width: 53px;
}

.centered-bottom {
  display: flex;
  width: 1000px;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

div.section-bottom-1 {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  gap: 0px;
}

.contact-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: -10px;
  margin-left: 92px;
  min-height: 112px;
  width: 333px;
  position: relative;
}

.place-5 {
  letter-spacing: 0;
  line-height: 70px;
  min-height: 48px;
  white-space: nowrap;
}

.admagiccogmailcom-1 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 16px;
  min-height: 52px;
  text-decoration: underline;
  width: 329px;
}

.copyright-1 {
  letter-spacing: 0;
  text-align: right;
  position: relative;
  left: 0;
  margin-top: 40px;
  line-height: 16px;
  position: absolute;
  top: 26px;
  width: 164px;
}

.home .go-button-div {
  margin-top: 30px;
}

#my-account-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: fixed;
  border: solid white;
  border-width: 3.5px;
  box-sizing: border-box;
  top: 17px;
  right: 18px;
  z-index: 9999;
  border-radius: 23px;
  padding: 7px 15px;
  background: rgba(93, 42, 29, 0.38);
  transition: all 0.2s ease-in-out;
}

#my-account-button img {
  max-width: 100%;
  height: auto;
  filter: invert(1);
}

#my-account-button label {
  color: white;
  font-family: var(--font-family-montserrat);
  font-size: 14.5px;
  font-style: normal;
  font-weight: 600;
  mix-blend-mode: lighten;
}

#my-account-button:hover {
  cursor: pointer;
  transform: scale(1.02);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;
}

#my-account-button label:hover {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  #automated-short-form {
    margin-top: 25px;
  }
  .go-button {
    height: 52px;
    padding: 0 40px;
    font-size: 19px;
    margin-top: 5px;
  }
  .stars {
    display: none;
  }
  p.login-text {
    line-height: 18px;
    font-size: 12px;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .stars-3 {
    display: none;
  }
  .section-middle-text {
    padding-left: 70px;
    padding-right: 70px;
  }
  #learn-your-audience {
    font-size: 38px;
    line-height: 55px;
    padding-top: 0px;
  }
  #our-comprehensive {
    font-size: 17px;
    line-height: 35px;
  }
}

@media screen and (max-width: 500px) {
  .section-middle-text {
    padding-left: 50px;
    padding-right: 50px;
  }
  .second-section {
    padding-bottom: 30px;
  }
}
