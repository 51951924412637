.welcome {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
}

.overlap-group1 {
  display: flex;
  gap: 10px;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 0px 100px 0px 50px;
  margin-left: 150px;
  margin-right: 120px;
  flex-direction: column;
}

.overlap-group2 {
  height: 100%;
  position: absolute;
  width: 100%;
}

.vector-2 {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 183px;
}

.waves {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 183px;
  z-index: 0;
}

.left-stars {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1px;
  left: 200px;
  min-height: 105px;
  position: absolute;
  top: 50px;
  width: 93px;
}

.star-container {
  align-self: flex-end;
  height: 45px;
  position: relative;
  width: 43px;
}

.vector {
  height: 59px;
  width: 59px;
}

.flex-col {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.overlap-group3 {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 100px;
}

.title {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 48px;
  font-weight: 700;
  height: 72px;
  letter-spacing: 0;
  line-height: 70px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.just-to-make-sure-yo {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 17px;
  font-weight: 400;
  height: 88px;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  padding-top: 10px;
}

.frame-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 37px;
  position: relative;
  width: fit-content;
}

.access-code {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  justify-content: center;
  position: relative;
  width: fit-content;
  padding-top: 20px;
}

.rectangle-161 {
  background-color: var(--white-rock);
  border-radius: 12px;
  height: 65px;
  min-width: 57.66px;
  position: relative;
}

.enter {
  cursor: pointer;
  width: 166px;
  height: 57px;
  position: relative;
  background: linear-gradient(180deg, #a38b6b 0%, #89765e 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
  outline: none;
  border: none;
  margin-top: 6px;

  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1em;

  justify-content: center;
  text-align: center;
  align-items: center;

  transition: all 0.18s ease-in-out;
}

.enter:hover {
  box-shadow: 0 2 10px rgba(45, 38, 37, 0.69);
  cursor: pointer;
  transition: all 0.18s ease-in-out;
}

.enter:disabled {
  background: linear-gradient(180deg, #a38b6b 0%, #89765e 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
  outline: none;
  border: none;

  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1em;

  text-align: center;
  align-items: center;
  opacity: 0.5;

  cursor: not-allowed;
}

.overlap-group {
  height: 59px;
  position: relative;
  width: 166px;
}

.rectangle-161-1 {
  background: linear-gradient(
    180deg,
    rgb(163, 139, 107) 0%,
    rgb(137.06, 118.46, 93.66) 100%
  );
  border-radius: 23px;
  box-shadow: 0px 4px 4px #00000040;
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 166px;
}

.place {
  color: #ffffff;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-weight: 700;
  left: 18px;
  letter-spacing: 0;
  line-height: 60px;
  position: absolute;
  text-align: center;
  top: 15px;
  transform: rotate(-180deg);
  white-space: nowrap;
  width: 129px;
}

.right-stars {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  inset: 50px 0px 0px auto;
  filter: brightness(0) saturate(100%) invert(94%) sepia(12%) saturate(676%)
    hue-rotate(327deg) brightness(86%) contrast(90%);
  z-index: 0;
}

.vector-1 {
  height: 51px;
  margin-top: 1.73px;
  width: 56px;
}

.star-container-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-left: 1px;
  min-height: 216px;
  position: relative;
  width: 140px;
  filter: brightness(0) saturate(100%) invert(94%) sepia(12%) saturate(676%)
    hue-rotate(327deg) brightness(86%) contrast(90%);
}

.star-22 {
  height: 177px;
  width: 133px;
  filter: brightness(0) saturate(100%) invert(94%) sepia(12%) saturate(676%)
    hue-rotate(327deg) brightness(86%) contrast(90%);
}

.vector-3 {
  height: 28px;
  width: 29px;
  margin-right: 20px;
  margin-top: 30px;
}

.cant-find-access-co {
  letter-spacing: 0;
  min-height: 87px;
  text-align: center;

  text-align: center;
  align-items: center;
  bottom: 37px;
  width: 100%;
  margin-top: auto;
}

/* TODO: center this text on the page */

.span1 {
  text-decoration: underline;
}
