.dashboard .horizontal-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
}

.dashboard-text {
    font-family: var(--font-family-montserrat);
    height: 0px;
    padding-left: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
    font-size: 35px;
    font-weight: 700;
    box-sizing: border-box;
}

.dashboard-user-icons {
    height: 100%;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 10px;
}

label.dashboard-user-icons {
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    border-radius: 23px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

    background-image: linear-gradient(
            90deg,
            rgba(225, 169, 245, 1),
            rgba(241, 114, 86, 1),
            rgba(224, 168, 25, 1),
            rgba(255, 214, 0, 1)
    );
    transition: all 0.4s ease;
}

/* TODO: make gradient moving effect on hover */

.dashboard-user-icons label.dashboard-user-icons:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.dashboard-company-info-lines + label.dashboard-user-icons:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

label.dashboard-user-icons img {
    display: block;
    cursor: pointer;
    display: block;
}

label.dashboard-user-icons button {
    background: none;
    outline: none;
    border: none;
    font-family: var(--font-family-montserrat);
    justify-content: center;
    font-weight: 600;
    font-size: 15px;
    white-space: nowrap;
    line-height: normal;
    cursor: pointer;
}

.dashboard-user-icons .background-button-solid {
    border-radius: 23px;
    background: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    height: 100%;
    padding: 3px;
    background-clip: content-box;
}

.dashboard-user-icons .container {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center; /* add this line */
}

.dashboard-user-icons span {
    font-family: var(--font-family-montserrat);
}

.dashboard .vertical-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 30px;
}

.dashboard-box {
    background: #fffefd;
    border: 1.5px solid rgba(45, 38, 37, 0.69);
    /* dashboard piece shadow */

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
    font-family: var(--font-family-montserrat);
    width: 100%;
}

.padded-dashboard-box > button {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.33);
    border-radius: 28px;

    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    /* Inside auto layout */
    width: fit-content;
    height: fit-content;

    border: none;
    outline: none;

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}

button.admagic-button .container {
    padding: 15px 30px;
    font-family: var(--font-family-montserrat);
    flex-direction: row;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.admagic-button {
    margin: 5px 0px;
    cursor: pointer;
    transition: all 0.4s ease;
    white-space: nowrap;
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    border-radius: 28px;
    border: none;
}

.admagic-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease;
}

.padded-dashboard-box {
    padding: 25px 40px;

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 8px;
}

.dashboard-company-info {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-montserrat);
    width: 100%;
    margin-bottom: 10px;
}

.dashboard-company-info-lines {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
    font-family: var(--font-family-montserrat);
    font-size: 14px;
}

.dashboard-box .your-content-text {
    padding: 20px 40px 5px 40px;
    font-weight: 600;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.92);
}

.dashboard-content-scroll .content-placeholder-text {
    font-size: 15px;
    font-weight: 200;
    align-self: center;
}

.dashboard-content-scroll .content-placeholder {
    /*fill entirety of flex parent*/
    width: 100%;
    height: 100%;
    min-height: 250px;
    border-radius: 23px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(189, 189, 189, 0.15);
    padding: 30px;
}

.dashboard-content-scroll {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: scroll;
    padding: 20px 20px 20px 20px;
    scroll-padding: auto;
    min-height: 250px;
}

.dashboard-content-scroll img {
    display: none;
}

::-webkit-scrollbar {
    width: 2px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    background-color: transparent;
    padding: 0 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(
            90deg,
            rgba(225, 169, 245, 0.7),
            rgba(241, 114, 86, 0.7),
            rgba(224, 168, 25, 0.7),
            rgba(255, 214, 0, 0.7)
    );
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    margin: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.08);
}

.dashboard-content-share {
    display: flex;
    flex-direction: row;
    gap: 22px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-flow: row wrap;
}

.dashboard-distribution-socials {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0 10px 0;
}

.dashboard-distribution-socials input {
    width: 100%;
    height: 100%;

    background: rgba(234, 234, 234, 0.82);
    border-radius: 12px;
    padding: 10px 15px;
    border: none;
    font-family: var(--font-family-montserrat);
}

.dashboard-distribution {
    padding: 20px 50px;
}

.send-socials-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.feedback-horizontal-div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px 33px;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 20px;
}

.feedback-horizontal-div input {
    width: 100%;
    height: 100%;

    background: rgba(234, 234, 234, 0.82);
    border-radius: 12px;
    padding: 10px 15px;
    border: none;
    font-family: var(--font-family-montserrat);
}

.feedback-vertical-div {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    align-items: stretch;
}

.feedback-vertical-div > textarea {
    font-family: var(--font-family-montserrat);
    flex-grow: 1;
    padding: 10px 15px;
    background: rgba(234, 234, 234, 0.82);
    border-radius: 12px;
    border: none;
    width: 100%;
    box-sizing: border-box; /* include padding and border in element size */
    resize: vertical; /* disable resizing */
    min-height: 147px;
    align-self: stretch;
}

.loading-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.79);
    backdrop-filter: blur(2px);
    transition: all 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family-montserrat);
    z-index: 1; /* Added z-index value */
}

.loading-popup .loading-popup-box {
    background: #2e2317;
    box-shadow: 0px 0px 20px 7px #e1a9f5;
    border-radius: 30px;
    width: 50%;
    height: fit-content;

    display: flex;
    flex-direction: column;
    padding: 60px 60px;

    gap: 40px;
}

.loading-text {
    font-family: var(--font-family-montserrat);
    font-size: 20px;
    color: #ffffff;
    font-weight: 700;
    font-size: 35px;
    padding-bottom: 20px;
}

.loading-text-gradient {
    background: linear-gradient(180deg, #e1a9f5 0%, #eea6ca 33.85%, #ffc3b8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 500;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.progress-bar-container {
    width: 100%;
    height: 40px;
    position: relative;
    border-radius: 35px;
    overflow: hidden;
    margin-top: 50px;
}

.progress-bar-child {
    width: 100%;
    height: 100%;
}

.progress {
    color: white;
    text-align: center;
    line-height: 75px;
    font-size: 35px;
    font-family: "Segoe UI";
    animation-direction: reverse;
    background: #e5405e;

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #e5405e 0%, #ffdb3a 45%, #3fffa2 100%);
}

.shrinker {
    background-color: black;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.timelapse {
    animation-name: timelapse;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.86, 0.05, 0.4, 0.96);
}

@keyframes timelapse {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

.loading-messages {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: var(--font-family-montserrat);
    justify-content: space-between;
    height: 100%;
}

.loading-messages .loading-message-transparent {
    color: rgba(255, 255, 255, 0);
    -webkit-text-fill-color: #554c41;
    outline: none;
    border: none;
    text-shadow: none;
}

.loading-messages .loading-message {
    transition: all 0.4s ease;
    height: 1.5em;
}

.dashboard .ad-video {
    z-index: 0;
}

.analytics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
}

.analytics-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #000000;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 16px;
    line-height: 135%;
    flex-wrap: wrap;
}

.analytics-category {
    font-weight: 500;
}

.analytics-tag {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 15px;
}

.dashboard-distribution-text {
    line-height: 20px;
}
/*Add fade-in for text here*/
