.frame-20 {
  align-items: flex-start;
  background-color: #fff9eb;
  border-radius: 12px;
  box-shadow: 0px 2px 4px #00000080;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  padding: 20px 8px 50px 8px;
  width: 33%;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
  font-family: var(--font-family-montserrat);
  min-width: 450px;
}

/*the 1012 number is super scuffed! didn't write resizing js function so its from trial and error*/
@media screen and (max-width: 1012px) {
  .frame-20 {
    margin: -50px 0px 0px 0px;
  }
}

.welcome-to-ad-magic {
  color: transparent;
  font-family: var(--font-family-montserrat);
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 41.4px;
  margin-left: 45px;
  min-height: 100px;
  min-width: 204px;
  position: relative;
  margin-top: 30px;
}

.welcome-to-ad-magic-error {
  color: transparent;
  font-family: var(--font-family-montserrat);
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 41.4px;
  margin-left: 45px;
  min-height: 100px;
  min-width: 204px;
  position: relative;
  margin-top: 30px;
  outline: 2px solid red;
}

.span0 {
  color: #423934;
  font-weight: 500;
  display: block;
}

.AdMagic {
  color: #d99def;
  font-size: 36px;
  font-weight: 900;
  line-height: 52px;
}

.frame-48 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
  padding: 0 40px;
}

.credentials {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.username {
  height: 70px;
  min-width: 452px;
  position: relative;
}

.frame-11-1 {
  align-items: flex-start;
  background-color: var(--green-white);
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 70px;
  justify-content: center;
  padding: 4px 22px;
  position: relative;
  width: 452px;
}

.frame-10-1 {
  align-items: flex-start;
  display: flex;
  gap: 430px;
  height: 30px;
  justify-content: center;
  padding: 0px 0px 0px 5px;
  position: relative;
  width: 171px;
}

.frame-9-1 {
  align-items: flex-start;
  display: flex;
  gap: 26px;
  margin-bottom: -16px;
  margin-left: -2.5px;
  margin-right: -2.5px;
  position: relative;
  width: fit-content;
}

.icon-mail {
  height: 24px;
  min-width: 30px;
  position: relative;
}

.username-1,
.password-1 {
  height: 46px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  width: 115px;
  font-family: var(--font-family-montserrat);
  font-size: 20px;
}

.password {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 11px;
  height: 70px;
  position: relative;
  width: fit-content;
}

.frame-11 {
  align-items: flex-start;
  background-color: var(--green-white);
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 70px;
  justify-content: flex-end;
  padding: 4px 22px;
  position: relative;
  width: 452px;
}

.frame-10 {
  height: 46px;
  margin-right: -1px;
  min-width: 409px;
  position: relative;
}

.frame-9 {
  align-items: flex-start;
  display: flex;
  gap: 26px;
  left: 4px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.icon-key,
.key {
  height: 27px;
  min-width: 27px;
  position: relative;
}

.icon-eye {
  height: 25px;
  left: 384px;
  position: absolute;
  top: 0;
  width: 25px;
}

.re-enter-password {
  height: 46px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  width: 200px;
  font-family: var(--font-family-montserrat);
  font-size: 20px;
}

.closed-eye {
  height: 10px;
  left: 385px;
  position: absolute;
  top: 14px;
  width: 23px;
}

.enter {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: flex-end;
  position: relative;
  width: fit-content;
}

.create-account-button {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(225, 169, 245) 0%,
    rgb(209.72, 145.43, 232.69) 94.27%
  );
  border-radius: 28px;
  box-shadow: 0px 2px 4px #00000040;
  cursor: pointer;
  height: 57px;
  position: relative;
  width: 100%;
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  outline: none;
  border: none;
  margin-top: 20px;
}

.create-account-button:disabled {
  background: rgba(225, 169, 245, 0.55);
  cursor: not-allowed;
}

/* TODO: add hover effect for above button */

.left-keep-signin {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 100%;
}

.keep-me-signed-in {
  height: 22px;
  min-width: 183px;
  position: relative;
  color: #a38b6b;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.keep-me-signed-in-1 {
  color: #a38b6b;
  font-family: var(--font-family-poppins);
  font-weight: 400;
  font-size: 13.5px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21.7px;
  position: relative;
  top: 0;
  white-space: nowrap;
  cursor: pointer;
}

.check {
  background-color: var(--white);
  border: none;
  border-radius: 4px;
  box-shadow: 0px 1px 4px #00000040;
  height: 16px;
  position: relative;
  width: 16px;
  cursor: pointer;
  outline: none;
}

label.signup {
  background: #f0ebde;
  width: 100%;
  border: none;
  border-radius: 23px;
  height: 57px;

  display: flex;
  flex-direction: row;
  gap: 5px;
  /* align items center in the y direction */
  align-items: center;
}

label.signup-error {
  background: #f0ebde;
  width: 100%;
  outline: 2px solid #ff0000;
  border-radius: 23px;
  height: 57px;

  display: flex;
  flex-direction: row;
  gap: 5px;
  /* align items center in the y direction */
  align-items: center;
}

img.signup-input-icon {
  width: 60px;
  padding: 0 20px 0 20px;
}

input.signup-text {
  background: transparent;
  border: none;
  border-radius: 23px;
  height: inherit;
  width: 100%;
  font-size: 16.2px;
  font-family: var(--font-family-montserrat);
  font-weight: 400;
  color: var(--black);
}

label.signup:focus-within {
  outline: 2px solid rgba(105, 76, 76, 0.47) !important;
}

label.signup-error:focus-within {
  outline: 2px solid rgba(105, 76, 76, 0.47) !important;
}

input#Email.signup-text {
  margin-left: -6px;
}

input.signup-text:focus {
  outline: none;
}

.password-message {
  color: #2d2b2b;
  font-size: 14px;
  font-family: var(--font-family-montserrat);
  font-weight: 500;
  margin-left: 20px;
  margin-right: auto;
}

.password-message-alert {
  color: #be0202;
  font-size: 14px;
  font-family: var(--font-family-montserrat);
  font-weight: 500;
  margin-left: 20px;
  margin-right: auto;
}

#verify-your-email {
  font-family: var(--font-family-montserrat);
  font-size: 25px;
  font-weight: 500;
  line-height: 41.4px;
  margin-bottom: 10px;
  position: relative;
  margin-top: 30px;
}

#verify-your-email-text {
  font-family: var(--font-family-montserrat);
  font-size: 15px;
  line-height: 24px;
  position: relative;
}

#resend-email-text {
  font-family: var(--font-family-montserrat);
  color: #4d2626;
  font-size: 13px;
  line-height: 15px;
  position: relative;
  margin-top: 10px;
  text-align: center;
}

#resend-email {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(239, 167, 141) 0%,
    rgb(234, 147, 122) 94.27%
  );
  border-radius: 28px;
  box-shadow: 0px 2px 4px #00000040;
  cursor: pointer;
  height: 53px;
  position: relative;
  width: 100%;
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  outline: none;
  border: none;
  margin-top: 25px;
}

#use-different-email {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(239, 190, 141) 0%,
    rgb(234, 172, 122) 94.27%
  );
  border-radius: 28px;
  box-shadow: 0px 2px 4px #00000040;
  cursor: pointer;
  height: 53px;
  position: relative;
  width: 100%;
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  outline: none;
  border: none;
  margin-top: 15px;
}

.email-verification {
  margin-left: 45px;
  margin-right: 45px;
}

.email-verification b {
  font-weight: 600;
}

.email-verification .header {
  font-size: 25px;
}
