.share-ad .back-button {
  position: absolute;
  top: 40px;
  left: 70px;
  font-family: var(--font-family-montserrat);
  font-weight: 500;
  font-size: 17px;
}

.share-ad a {
  color: black;
  /*  un underline*/
  text-decoration: none;
}

.share-ad .horizontal-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  height: fit-content;
  gap: 80px;
}

.share-ad .video-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.share-ad .vertical-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 500px;
  padding-bottom: 100px;
}

.share-ad .header-text {
  font-family: var(--font-family-montserrat);
  font-weight: 500;
  padding-top: 100px;
  font-weight: 700;
  font-size: 43px;
}

.share-ad .sub-flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.share-ad .subheading-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  color: #a38b6b;
  font-family: var(--font-family-montserrat);
}

.share-ad button {
  border-radius: 28px;

  width: 109px;
  height: 43.66px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  outline: none;
  border: none;
  font-family: var(--font-family-montserrat);
  transition: 0.3s;
  box-sizing: border-box;
}

.share-ad button:hover {
  transform: scale(1.05);
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.share-ad button.SAVE {
  background: linear-gradient(180deg, #3a2b19 0%, #3a2b19 100%);
}

.share-ad button.SHARE {
  background: linear-gradient(
    224.83deg,
    #e1a9f5 -83.1%,
    #d093e6 16.18%,
    #f36f56 138.18%
  );
}

.share-ad div.LINK {
  border: 3px solid #f56f57;
  width: fit-content;
  height: fit-content;
  padding: 10px 40px;
  font-weight: 400;
  color: black;
  border-radius: 28px;
  font-family: var(--font-family-montserrat);
  background: white;
}

.share-ad .sub-flex .copy {
  height: 2em;
}

.share-ad .link-horizontal-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: fit-content;
  gap: 20px;
  align-items: center;
  cursor: pointer;
}

.share-ad video.ad-video {
  max-height: 100%;
  object-fit: contain;

}

.ad-video-container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
