.show-ads {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.show-ads .vertical-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  font-family: var(--font-family-montserrat);
  height: 100%;
  align-items: center;
}
.vertical-flex-separator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--font-family-montserrat);
  align-items: center;
}
.show-ads .horizontal-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  font-family: var(--font-family-montserrat);
}

.show-ads .video-flex {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 120px;
  width: 80%;
  height: fit-content;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.show-ads .header-text {
  display: flex;
  flex-direction: row;
  color: #000;
  font-weight: 700;
  font-size: 35px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 80px;
}

.show-ads .subheading-text {
  font-weight: 400;
  font-size: 18px;
  box-sizing: border-box;
  width: 100%;
  line-height: 1.5;
  display: flex;
  flex-direction: row;
}

.show-ads .video-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  cursor: pointer;
  padding: 20px;
}

.show-ads .ad-video {
  border-radius: 20px;
  transition: 0.3s;
}
.show-ads .ad-video:hover {
  transform: scale(1.05);
  transition: 0.3s;
}
.show-ads .ad-video-selected {
  transform: scale(1.1);
  border-radius: 20px;
  border: 7px dashed rgba(60, 29, 1, 0.71);
  transition: 0.3s;
}

.show-ads button {
  background: #745d45;
  border-radius: 12px;
  width: 109px;
  height: 43.66px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  outline: none;
  border: none;
  font-family: var(--font-family-montserrat);
  transition: 0.3s;
}

.show-ads button:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  transition: 0.3s;
}

.show-ads button:disabled {
  background: #423934;
  box-shadow: none;
  transform: scale(1);
  transition: 0.3s;
  cursor: not-allowed;
}

.show-ads .footer-text {
  color: #423934;
  font-weight: 400;
  font-size: 18px;
  padding: 20px 0 50px 0;
}
