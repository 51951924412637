.form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  gap: 15px;
  margin-top: 50px;
}

.form {
  align-items: flex-end;
  background-color: var(--buttery-white);
  border-radius: 50px;
  height: 50px;
  min-width: 310px;
  position: relative;
}

.city-form {
  letter-spacing: 0;
  line-height: 30px;
  min-height: 33px;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 50px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

#home-form .city-form:hover {
  box-shadow: 0 0 10px #c27752;
  transition: all 0.3s ease-in-out;
}

#home-form .city-form:focus,
.ant-select-focused {
  box-shadow: 0 0 10px #c27752;
}

.name-form {
  letter-spacing: 0;
  line-height: 30px;
  min-height: 32px;
  white-space: nowrap;
  width: 230px;
}

#home-form .city-form .ant-select-selector {
  border-radius: 50px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

#home-form .ant-select-selection-placeholder,
.ant-select-selection-search,
.ant-select-selection-search-input {
  display: inline-flex;
  align-items: center;
  line-height: 1em;
  font-size: 17px;
  font-weight: 300;
  font-family: var(--font-family-montserrat);
  padding: 0 10px;
}

.ant-select-dropdown {
  background-color: #fff9eb;
  font-family: var(--font-family-montserrat);
  z-index: 2;
}

@media screen and (max-width: 800px) {
  #home-form .ant-select-selection-placeholder,
  .ant-select-selection-search,
  .ant-select-selection-search-input {
    line-height: 1em;
    font-size: 15px;
  }

  .form {
    border-radius: 50px;
    height: 40px;
  }

  .form-div {
    margin-top: 40px;
  }
}

/*@media screen and (max-width: 700px) {*/
/*  .form {*/
/*    border-radius: 50px;*/
/*    height: 40px;*/
/*  }*/
/*}*/
