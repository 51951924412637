@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Montserrat:400,700,300,500,600,800,200|Source+Sans+3:400|Poppins:400,700,900,500,600";
@import "https://px.animaapp.com/6275b9721203600aeca6fe44.6275b9746bea9bff3434837c.OHhrErV.rcp.png";
@import "https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap";
.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  z-index: 1;
  background-color: #fff8ea;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

* {
  box-sizing: border-box;
}

.YTDwlW_invalidPageContainer {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  font-family: var(--font-family-montserrat);
  text-align: center;
  background-color: #fff8ea;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
  display: flex;
}

.YTDwlW_invalidPageContainer h1 {
  box-sizing: border-box;
  padding-bottom: 25px;
  font-family: inherit;
  font-size: 4rem;
  font-weight: 700;
}

.YTDwlW_invalidPageContainer p {
  padding-bottom: 30px;
  font-size: 1.2rem;
  font-weight: 400;
}

.YTDwlW_invalidPageContainer button {
  cursor: pointer;
  height: 57px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: .4px;
  background: linear-gradient(#f78768 0%, #cf6547 152.17%);
  border: none;
  border-radius: 28px;
  outline: none;
  align-items: center;
  margin: 5px 0;
  padding: 0 25px;
  font-family: inherit;
  font-size: 1.1rem;
  font-weight: 600;
  box-shadow: 0 2px 4px #00000040;
}



.share-ad .back-button {
  font-family: var(--font-family-montserrat);
  font-size: 17px;
  font-weight: 500;
  position: absolute;
  top: 40px;
  left: 70px;
}

.share-ad a {
  color: #000;
  text-decoration: none;
}

.share-ad .horizontal-flex {
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 80px;
  display: flex;
}

.share-ad .video-flex {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.share-ad .vertical-flex {
  height: 100vh;
  width: 500px;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 100px;
  display: flex;
}

.share-ad .header-text {
  font-family: var(--font-family-montserrat);
  padding-top: 100px;
  font-size: 43px;
  font-weight: 700;
}

.share-ad .sub-flex {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.share-ad .subheading-text {
  color: #a38b6b;
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
  font-family: var(--font-family-montserrat);
}

.share-ad button {
  width: 109px;
  height: 43.66px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-family: var(--font-family-montserrat);
  box-sizing: border-box;
  border: none;
  border-radius: 28px;
  outline: none;
  transition: all .3s;
}

.share-ad button:hover {
  transition: all .3s;
  transform: scale(1.05);
  box-shadow: 0 4px 4px #00000040;
}

.share-ad button.SAVE {
  background: linear-gradient(#3a2b19 0% 100%);
}

.share-ad button.SHARE {
  background: linear-gradient(224.83deg, #e1a9f5 -83.1%, #d093e6 16.18%, #f36f56 138.18%);
}

.share-ad div.LINK {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #000;
  font-weight: 400;
  font-family: var(--font-family-montserrat);
  background: #fff;
  border: 3px solid #f56f57;
  border-radius: 28px;
  padding: 10px 40px;
}

.share-ad .sub-flex .copy {
  height: 2em;
}

.share-ad .link-horizontal-flex {
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.share-ad video.ad-video {
  max-height: 100%;
  object-fit: contain;
}

.ad-video-container {
  height: 80vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.show-ads {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.show-ads .vertical-flex {
  font-family: var(--font-family-montserrat);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
}

.vertical-flex-separator {
  font-family: var(--font-family-montserrat);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.show-ads .horizontal-flex {
  font-family: var(--font-family-montserrat);
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  display: flex;
}

.show-ads .video-flex {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 120px;
  display: flex;
}

.show-ads .header-text {
  color: #000;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  margin-top: 80px;
  font-size: 35px;
  font-weight: 700;
  display: flex;
}

.show-ads .subheading-text {
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.show-ads .video-container {
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  padding: 20px;
  display: flex;
}

.show-ads .ad-video {
  border-radius: 20px;
  transition: all .3s;
}

.show-ads .ad-video:hover {
  transition: all .3s;
  transform: scale(1.05);
}

.show-ads .ad-video-selected {
  border: 7px dashed #3c1d01b5;
  border-radius: 20px;
  transition: all .3s;
  transform: scale(1.1);
}

.show-ads button {
  width: 109px;
  height: 43.66px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-family: var(--font-family-montserrat);
  background: #745d45;
  border: none;
  border-radius: 12px;
  outline: none;
  transition: all .3s;
}

.show-ads button:hover {
  transition: all .3s;
  transform: scale(1.1);
  box-shadow: 0 2px 4px #00000080;
}

.show-ads button:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background: #423934;
  transition: all .3s;
  transform: scale(1);
}

.show-ads .footer-text {
  color: #423934;
  padding: 20px 0 50px;
  font-size: 18px;
  font-weight: 400;
}

.dashboard .horizontal-flex {
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  display: flex;
}

.dashboard-text {
  font-family: var(--font-family-montserrat);
  height: 0;
  text-align: left;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
  padding-left: 20px;
  font-size: 35px;
  font-weight: 700;
  display: flex;
}

.dashboard-user-icons {
  height: 100%;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  display: flex;
}

label.dashboard-user-icons {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-sizing: border-box;
  background-image: linear-gradient(90deg, #e1a9f5, #f17256, #e0a819, #ffd600);
  border-radius: 23px;
  transition: all .4s;
  box-shadow: 0 0 3px #00000040;
}

.dashboard-user-icons label.dashboard-user-icons:hover, .dashboard-company-info-lines + label.dashboard-user-icons:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 0 10px #00000040;
}

label.dashboard-user-icons img {
  cursor: pointer;
  display: block;
}

label.dashboard-user-icons button {
  font-family: var(--font-family-montserrat);
  white-space: nowrap;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
}

.dashboard-user-icons .background-button-solid {
  box-sizing: border-box;
  height: 100%;
  background: #ffffffe6 padding-box content-box;
  border-radius: 23px;
  padding: 3px;
}

.dashboard-user-icons .container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.dashboard-user-icons span {
  font-family: var(--font-family-montserrat);
}

.dashboard .vertical-flex {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  display: flex;
}

.dashboard-box {
  font-family: var(--font-family-montserrat);
  width: 100%;
  background: #fffefd;
  border: 1.5px solid #2d2625b0;
  border-radius: 23px;
  box-shadow: 0 4px 4px #00000040;
}

.padded-dashboard-box > button {
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  border-radius: 28px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  box-shadow: 0 2px 2px #00000054;
}

button.admagic-button .container {
  font-family: var(--font-family-montserrat);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px 30px;
  display: flex;
}

.admagic-button {
  cursor: pointer;
  white-space: nowrap;
  color: #fff;
  border: none;
  border-radius: 28px;
  margin: 5px 0;
  font-size: 18px;
  font-weight: 700;
  transition: all .4s;
}

.admagic-button:hover {
  transition: all .4s;
  transform: scale(1.05);
  box-shadow: 0 0 10px #00000040;
}

.padded-dashboard-box {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 25px 40px;
  display: flex;
}

.dashboard-company-info {
  font-family: var(--font-family-montserrat);
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  display: flex;
}

.dashboard-company-info-lines {
  width: 100%;
  font-family: var(--font-family-montserrat);
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  display: flex;
}

.dashboard-box .your-content-text {
  color: #000000eb;
  padding: 20px 40px 5px;
  font-size: 22px;
  font-weight: 600;
}

.dashboard-content-scroll .content-placeholder-text {
  align-self: center;
  font-size: 15px;
  font-weight: 200;
}

.dashboard-content-scroll .content-placeholder {
  width: 100%;
  height: 100%;
  min-height: 250px;
  background-color: #bdbdbd26;
  border-radius: 23px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

.dashboard-content-scroll {
  min-height: 250px;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  scroll-padding: auto;
  display: flex;
  overflow-x: scroll;
}

.dashboard-content-scroll img {
  display: none;
}

::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  background-color: #0000;
  border-radius: 10px;
  padding: 0 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #e1a9f5b3, #f17256b3, #e0a819b3, #ffd600b3);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #00000014;
  border-radius: 10px;
  margin: 20px;
}

.dashboard-content-share {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 22px;
  padding: 20px;
  display: flex;
}

.dashboard-distribution-socials {
  flex-direction: column;
  gap: 10px;
  padding: 20px 0 10px;
  display: flex;
}

.dashboard-distribution-socials input {
  width: 100%;
  height: 100%;
  font-family: var(--font-family-montserrat);
  background: #eaeaead1;
  border: none;
  border-radius: 12px;
  padding: 10px 15px;
}

.dashboard-distribution {
  padding: 20px 50px;
}

.send-socials-button {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.feedback-horizontal-div {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 33px 20px;
  display: flex;
}

.feedback-horizontal-div input {
  width: 100%;
  height: 100%;
  font-family: var(--font-family-montserrat);
  background: #eaeaead1;
  border: none;
  border-radius: 12px;
  padding: 10px 15px;
}

.feedback-vertical-div {
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  display: flex;
}

.feedback-vertical-div > textarea {
  font-family: var(--font-family-montserrat);
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  min-height: 147px;
  background: #eaeaead1;
  border: none;
  border-radius: 12px;
  flex-grow: 1;
  align-self: stretch;
  padding: 10px 15px;
}

.loading-popup {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  font-family: var(--font-family-montserrat);
  z-index: 1;
  background: #000000c9;
  justify-content: center;
  align-items: center;
  transition: all .4s;
  display: flex;
  position: fixed;
}

.loading-popup .loading-popup-box {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  background: #2e2317;
  border-radius: 30px;
  flex-direction: column;
  gap: 40px;
  padding: 60px;
  display: flex;
  box-shadow: 0 0 20px 7px #e1a9f5;
}

.loading-text {
  font-family: var(--font-family-montserrat);
  color: #fff;
  padding-bottom: 20px;
  font-size: 35px;
  font-weight: 700;
}

.loading-text-gradient {
  background: linear-gradient(#e1a9f5 0%, #eea6ca 33.85%, #ffc3b8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  flex-direction: column;
  gap: 20px;
  font-size: 17px;
  font-weight: 500;
  display: flex;
}

.progress-bar-container {
  width: 100%;
  height: 40px;
  border-radius: 35px;
  margin-top: 50px;
  position: relative;
  overflow: hidden;
}

.progress-bar-child {
  width: 100%;
  height: 100%;
}

.progress {
  color: #fff;
  text-align: center;
  background: linear-gradient(to right, #e5405e 0%, #ffdb3a 45%, #3fffa2 100%);
  font-family: Segoe UI;
  font-size: 35px;
  line-height: 75px;
  animation-direction: reverse;
}

.shrinker {
  width: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  right: 0;
}

.timelapse {
  animation-name: timelapse;
  animation-timing-function: cubic-bezier(.86, .05, .4, .96);
  animation-fill-mode: forwards;
}

@keyframes timelapse {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.loading-messages {
  font-family: var(--font-family-montserrat);
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  display: flex;
}

.loading-messages .loading-message-transparent {
  color: #fff0;
  -webkit-text-fill-color: #554c41;
  text-shadow: none;
  border: none;
  outline: none;
}

.loading-messages .loading-message {
  height: 1.5em;
  transition: all .4s;
}

.dashboard .ad-video {
  z-index: 0;
}

.analytics {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 0;
  display: flex;
}

.analytics-group {
  color: #000;
  flex-flow: wrap;
  flex: none;
  order: 0;
  align-items: center;
  gap: 10px;
  padding: 0;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  line-height: 135%;
  display: flex;
}

.analytics-category {
  font-weight: 500;
}

.analytics-tag {
  border-radius: 15px;
  padding: 5px 15px;
  font-size: 15px;
  display: inline-block;
}

.dashboard-distribution-text {
  line-height: 20px;
}

.HVNnhq_createAccount {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  z-index: 2;
  border: 1px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.HVNnhq_vector2 {
  height: 100%;
  width: 183px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.HVNnhq_illustration {
  height: 28em;
  margin-top: 100px;
  margin-bottom: 206.24px;
}

@media screen and (max-width: 1012px) {
  .HVNnhq_illustration {
    display: none;
  }
}

.HVNnhq_account-overlap-group {
  height: 100%;
  width: 100%;
  min-width: 1703px;
  background: linear-gradient(#fff6e3 0%, #fffaef 100%);
  justify-content: center;
  align-items: center;
  gap: 55px;
  display: flex;
}

.frame-20 {
  width: 33%;
  font-family: var(--font-family-montserrat);
  min-width: 450px;
  background-color: #fff9eb;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 50px;
  padding: 20px 8px 50px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px #00000080;
}

@media screen and (max-width: 1012px) {
  .frame-20 {
    margin: -50px 0 0;
  }
}

.welcome-to-ad-magic {
  color: #0000;
  font-family: var(--font-family-montserrat);
  letter-spacing: 0;
  min-height: 100px;
  min-width: 204px;
  margin-top: 30px;
  margin-left: 45px;
  font-size: 25px;
  font-weight: 400;
  line-height: 41.4px;
  position: relative;
}

.welcome-to-ad-magic-error {
  color: #0000;
  font-family: var(--font-family-montserrat);
  letter-spacing: 0;
  min-height: 100px;
  min-width: 204px;
  outline: 2px solid red;
  margin-top: 30px;
  margin-left: 45px;
  font-size: 25px;
  font-weight: 400;
  line-height: 41.4px;
  position: relative;
}

.span0 {
  color: #423934;
  font-weight: 500;
  display: block;
}

.AdMagic {
  color: #d99def;
  font-size: 36px;
  font-weight: 900;
  line-height: 52px;
}

.frame-48 {
  width: 100%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 20px;
  padding: 0 40px;
  display: flex;
  position: relative;
}

.credentials {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  display: flex;
  position: relative;
}

.username {
  height: 70px;
  min-width: 452px;
  position: relative;
}

.frame-11-1 {
  background-color: var(--green-white);
  height: 70px;
  width: 452px;
  border-radius: 23px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 22px;
  display: flex;
  position: relative;
}

.frame-10-1 {
  height: 30px;
  width: 171px;
  justify-content: center;
  align-items: flex-start;
  gap: 430px;
  padding: 0 0 0 5px;
  display: flex;
  position: relative;
}

.frame-9-1 {
  width: -moz-fit-content;
  width: fit-content;
  align-items: flex-start;
  gap: 26px;
  margin-bottom: -16px;
  margin-left: -2.5px;
  margin-right: -2.5px;
  display: flex;
  position: relative;
}

.icon-mail {
  height: 24px;
  min-width: 30px;
  position: relative;
}

.username-1, .password-1 {
  height: 46px;
  letter-spacing: 0;
  width: 115px;
  line-height: 26px;
  font-family: var(--font-family-montserrat);
  margin-top: -1px;
  font-size: 20px;
  position: relative;
}

.password {
  height: 70px;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  align-items: flex-end;
  gap: 11px;
  display: flex;
  position: relative;
}

.frame-11 {
  background-color: var(--green-white);
  height: 70px;
  width: 452px;
  border-radius: 23px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 22px;
  display: flex;
  position: relative;
}

.frame-10 {
  height: 46px;
  min-width: 409px;
  margin-right: -1px;
  position: relative;
}

.frame-9 {
  width: -moz-fit-content;
  width: fit-content;
  align-items: flex-start;
  gap: 26px;
  display: flex;
  position: absolute;
  top: 0;
  left: 4px;
}

.icon-key, .key {
  height: 27px;
  min-width: 27px;
  position: relative;
}

.icon-eye {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0;
  left: 384px;
}

.re-enter-password {
  height: 46px;
  letter-spacing: 0;
  width: 200px;
  line-height: 26px;
  font-family: var(--font-family-montserrat);
  margin-top: -1px;
  font-size: 20px;
  position: relative;
}

.closed-eye {
  height: 10px;
  width: 23px;
  position: absolute;
  top: 14px;
  left: 385px;
}

.enter {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 14px;
  display: flex;
  position: relative;
}

.create-account-button {
  cursor: pointer;
  height: 57px;
  width: 100%;
  color: var(--white);
  font-family: var(--font-family-poppins);
  text-align: center;
  background: linear-gradient(#e1a9f5 0%, #d291e9 94.27%);
  border: none;
  border-radius: 28px;
  outline: none;
  align-items: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  box-shadow: 0 2px 4px #00000040;
}

.create-account-button:disabled {
  cursor: not-allowed;
  background: #e1a9f58c;
}

.left-keep-signin {
  width: 100%;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.keep-me-signed-in {
  height: 22px;
  min-width: 183px;
  color: #a38b6b;
  cursor: pointer;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
  display: flex;
  position: relative;
}

.keep-me-signed-in-1 {
  color: #a38b6b;
  font-family: var(--font-family-poppins);
  letter-spacing: 0;
  white-space: nowrap;
  cursor: pointer;
  font-size: 13.5px;
  font-weight: 400;
  line-height: 21.7px;
  position: relative;
  top: 0;
}

.check {
  background-color: var(--white);
  height: 16px;
  width: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  outline: none;
  position: relative;
  box-shadow: 0 1px 4px #00000040;
}

label.signup {
  width: 100%;
  height: 57px;
  background: #f0ebde;
  border: none;
  border-radius: 23px;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  display: flex;
}

label.signup-error {
  width: 100%;
  height: 57px;
  background: #f0ebde;
  border-radius: 23px;
  outline: 2px solid red;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  display: flex;
}

img.signup-input-icon {
  width: 60px;
  padding: 0 20px;
}

input.signup-text {
  height: inherit;
  width: 100%;
  font-size: 16.2px;
  font-family: var(--font-family-montserrat);
  color: var(--black);
  background: none;
  border: none;
  border-radius: 23px;
  font-weight: 400;
}

label.signup:focus-within, label.signup-error:focus-within {
  outline: 2px solid #694c4c78 !important;
}

input#Email.signup-text {
  margin-left: -6px;
}

input.signup-text:focus {
  outline: none;
}

.password-message {
  color: #2d2b2b;
  font-size: 14px;
  font-family: var(--font-family-montserrat);
  margin-left: 20px;
  margin-right: auto;
  font-weight: 500;
}

.password-message-alert {
  color: #be0202;
  font-size: 14px;
  font-family: var(--font-family-montserrat);
  margin-left: 20px;
  margin-right: auto;
  font-weight: 500;
}

#verify-your-email {
  font-family: var(--font-family-montserrat);
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 500;
  line-height: 41.4px;
  position: relative;
}

#verify-your-email-text {
  font-family: var(--font-family-montserrat);
  font-size: 15px;
  line-height: 24px;
  position: relative;
}

#resend-email-text {
  font-family: var(--font-family-montserrat);
  color: #4d2626;
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
  line-height: 15px;
  position: relative;
}

#resend-email {
  cursor: pointer;
  height: 53px;
  width: 100%;
  color: var(--white);
  font-family: var(--font-family-poppins);
  letter-spacing: .5px;
  text-align: center;
  background: linear-gradient(#efa78d 0%, #ea937a 94.27%);
  border: none;
  border-radius: 28px;
  outline: none;
  align-items: center;
  margin-top: 25px;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  box-shadow: 0 2px 4px #00000040;
}

#use-different-email {
  cursor: pointer;
  height: 53px;
  width: 100%;
  color: var(--white);
  font-family: var(--font-family-poppins);
  letter-spacing: .5px;
  text-align: center;
  background: linear-gradient(#efbe8d 0%, #eaac7a 94.27%);
  border: none;
  border-radius: 28px;
  outline: none;
  align-items: center;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  box-shadow: 0 2px 4px #00000040;
}

.email-verification {
  margin-left: 45px;
  margin-right: 45px;
}

.email-verification b {
  font-weight: 600;
}

.email-verification .header {
  font-size: 25px;
}

input.code-number {
  width: 68px;
  height: 72px;
  text-align: center;
  color: #4d413fed;
  caret-color: #0000;
  background: #ede8db;
  border: none;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 700;
}

input.code-number:focus {
  border: 5px solid #6928181c;
  outline: none;
}

input.invalid {
  border: 5px solid #ff7878;
  outline: none;
}

input.invalid:focus {
  border: 5px solid #fabe7f;
  outline: none;
}

.welcome {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  border: 1px;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.overlap-group1 {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-left: 150px;
  margin-right: 120px;
  padding: 0 100px 0 50px;
  display: flex;
}

.overlap-group2 {
  height: 100%;
  width: 100%;
  position: absolute;
}

.vector-2 {
  height: 100%;
  width: 183px;
  position: absolute;
  top: 0;
  left: 0;
}

.waves {
  height: 100%;
  width: 183px;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.left-stars {
  min-height: 105px;
  width: 93px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  display: flex;
  position: absolute;
  top: 50px;
  left: 200px;
}

.star-container {
  height: 45px;
  width: 43px;
  align-self: flex-end;
  position: relative;
}

.vector {
  height: 59px;
  width: 59px;
}

.flex-col {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.overlap-group3 {
  width: 100%;
  height: 100%;
  position: relative;
}

.content {
  width: 100%;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0 0;
  display: flex;
}

.frame-5 {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 100px;
  display: flex;
  position: relative;
}

.title {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  height: 72px;
  letter-spacing: 0;
  white-space: nowrap;
  margin-top: -1px;
  font-size: 48px;
  font-weight: 700;
  line-height: 70px;
  position: relative;
}

.just-to-make-sure-yo {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  height: 88px;
  letter-spacing: 0;
  padding-top: 10px;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.frame-4 {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 37px;
  display: flex;
  position: relative;
}

.access-code {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  padding-top: 20px;
  display: flex;
  position: relative;
}

.rectangle-161 {
  background-color: var(--white-rock);
  height: 65px;
  min-width: 57.66px;
  border-radius: 12px;
  position: relative;
}

.enter {
  cursor: pointer;
  width: 166px;
  height: 57px;
  color: #fff;
  text-align: center;
  background: linear-gradient(#a38b6b 0%, #89765e 100%);
  border: none;
  border-radius: 23px;
  outline: none;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  transition: all .18s ease-in-out;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.enter:hover {
  cursor: pointer;
  transition: all .18s ease-in-out;
  box-shadow: 0 2px 10px #2d2625b0;
}

.enter:disabled {
  color: #fff;
  text-align: center;
  opacity: .5;
  cursor: not-allowed;
  background: linear-gradient(#a38b6b 0%, #89765e 100%);
  border: none;
  border-radius: 23px;
  outline: none;
  align-items: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  box-shadow: 0 4px 4px #00000040;
}

.overlap-group {
  height: 59px;
  width: 166px;
  position: relative;
}

.rectangle-161-1 {
  height: 57px;
  width: 166px;
  background: linear-gradient(#a38b6b 0%, #89765e 100%);
  border-radius: 23px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px #00000040;
}

.place {
  color: #fff;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 129px;
  font-weight: 700;
  line-height: 60px;
  position: absolute;
  top: 15px;
  left: 18px;
  transform: rotate(-180deg);
}

.right-stars {
  filter: brightness(0) saturate() invert(94%) sepia(12%) saturate(676%) hue-rotate(327deg) brightness(86%) contrast(90%);
  z-index: 0;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  position: fixed;
  inset: 50px 0 0 auto;
}

.vector-1 {
  height: 51px;
  width: 56px;
  margin-top: 1.73px;
}

.star-container-1 {
  min-height: 216px;
  width: 140px;
  filter: brightness(0) saturate() invert(94%) sepia(12%) saturate(676%) hue-rotate(327deg) brightness(86%) contrast(90%);
  flex-direction: column;
  align-items: flex-end;
  gap: 18px;
  margin-left: 1px;
  display: flex;
  position: relative;
}

.star-22 {
  height: 177px;
  width: 133px;
  filter: brightness(0) saturate() invert(94%) sepia(12%) saturate(676%) hue-rotate(327deg) brightness(86%) contrast(90%);
}

.vector-3 {
  height: 28px;
  width: 29px;
  margin-top: 30px;
  margin-right: 20px;
}

.cant-find-access-co {
  letter-spacing: 0;
  min-height: 87px;
  text-align: center;
  text-align: center;
  width: 100%;
  align-items: center;
  margin-top: auto;
  bottom: 37px;
}

.span1 {
  text-decoration: underline;
}

.star-26 {
  height: 25px;
  min-width: 25px;
  filter: brightness(0) saturate() invert(94%) sepia(12%) saturate(676%) hue-rotate(327deg) brightness(86%) contrast(90%);
  align-items: flex-start;
  padding: 0 .6px;
  display: flex;
  position: absolute;
  top: 7px;
  left: 29px;
}

.icon-star {
  height: 24px;
  width: 24px;
}

.star-26.star-27 {
  height: 45px;
  min-width: 43px;
  padding: 0 1.1px;
  top: -2px;
  left: 20px;
}

.star-26.star-27 .icon-star {
  height: 41px;
  width: 41px;
}

.star-26.star-28 {
  height: 34px;
  left: unset;
  min-width: 34px;
  position: unset;
  top: unset;
  margin-right: 14.38px;
  padding: 0 .8px;
}

.star-26.star-28 .icon-star {
  height: 31px;
  width: 32px;
}

div.section-bottom-1 {
  flex-direction: column;
  gap: 0;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.bottom-categories {
  flex-flow: wrap;
  align-items: flex-start;
  gap: 20px 15vw;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  position: relative;
  left: 0;
}

div.bottom-category h1 {
  margin-bottom: 7px;
}

div.bottom-category a {
  line-height: 25px;
  text-decoration-line: underline;
  display: block;
}

.copyright {
  text-align: right;
  justify-self: right;
  padding-bottom: 20px;
}

.heart-1-1 {
  flex-direction: row;
  align-items: start;
  gap: 15px;
  line-height: 55px;
  display: flex;
}

.icon-star {
  margin-top: 15px;
}

div.bullet-text {
  font-size: 18px;
  font-family: var(--font-family-montserrat);
  color: #2d2a28;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  div.bullet-text {
    font-size: 16.5px;
    line-height: 43px;
  }

  .icon-star {
    width: 18px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 507px) {
  div.bullet-text {
    line-height: 30px;
  }

  .icon-star {
    margin-top: 3px;
  }
}

.form-div {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 50px;
  display: flex;
  position: relative;
}

.form {
  background-color: var(--buttery-white);
  height: 50px;
  min-width: 310px;
  border-radius: 50px;
  align-items: flex-end;
  position: relative;
}

.city-form {
  letter-spacing: 0;
  min-height: 33px;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: #0000;
  border-radius: 50px;
  line-height: 30px;
  transition: all .3s ease-in-out;
}

#home-form .city-form:hover {
  transition: all .3s ease-in-out;
  box-shadow: 0 0 10px #c27752;
}

#home-form .city-form:focus, .ant-select-focused {
  box-shadow: 0 0 10px #c27752;
}

.name-form {
  letter-spacing: 0;
  min-height: 32px;
  white-space: nowrap;
  width: 230px;
  line-height: 30px;
}

#home-form .city-form .ant-select-selector {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 50px;
  transition: all .3s ease-in-out;
}

#home-form .ant-select-selection-placeholder, .ant-select-selection-search, .ant-select-selection-search-input {
  font-size: 17px;
  font-weight: 300;
  line-height: 1em;
  font-family: var(--font-family-montserrat);
  align-items: center;
  padding: 0 10px;
  display: inline-flex;
}

.ant-select-dropdown {
  font-family: var(--font-family-montserrat);
  z-index: 2;
  background-color: #fff9eb;
}

@media screen and (max-width: 800px) {
  #home-form .ant-select-selection-placeholder, .ant-select-selection-search, .ant-select-selection-search-input {
    font-size: 15px;
    line-height: 1em;
  }

  .form {
    height: 40px;
    border-radius: 50px;
  }

  .form-div {
    margin-top: 40px;
  }
}

#right-asterisk {
  top: -20px;
  left: 0;
}

#right-big-star {
  top: 0;
  left: -130px;
}

.home-neil {
  background-color: var(--emperor);
  min-height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  border: 1px;
  align-items: flex-start;
  display: flex;
  overflow-x: hidden;
}

.overlap-group10 {
  height: 100%;
  width: 100%;
  margin-top: -31px;
  position: relative;
}

.cream-wave-1 {
  width: 100%;
  position: absolute;
  top: 838px;
}

.cream-square-2 {
  background-color: var(--sazerac);
  height: 625px;
  width: 1993px;
  position: absolute;
  top: 970px;
  left: 21px;
}

.headline {
  height: 430px;
  width: 1234px;
  margin-right: 4px;
  position: relative;
}

.stars {
  height: 300px;
  min-width: 1234px;
  z-index: -1;
  justify-content: center;
  align-items: center;
  gap: 60em;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px;
  display: flex;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
}

.stars-left {
  height: 248px;
  width: 225px;
  position: relative;
}

.overlap-group2-8 {
  height: 240px;
  width: 173px;
  position: absolute;
  top: 0;
  left: 70px;
}

.line-container-6 {
  height: 56px;
  width: 56px;
  position: absolute;
  top: 140px;
  left: -30px;
}

.line-6-4 {
  height: 50px;
  width: 31px;
  position: absolute;
  top: 2px;
  left: 13px;
}

.line-1-6 {
  height: 56px;
  width: 3px;
  position: absolute;
  top: 0;
  left: 27px;
}

.line-2-6 {
  height: 3px;
  width: 56px;
  position: absolute;
  top: 25px;
  left: 0;
}

.line-3-1 {
  height: 31px;
  width: 50px;
  position: absolute;
  top: 11px;
  left: 2px;
}

.line-5-5 {
  height: 50px;
  width: 31px;
  position: absolute;
  top: 3px;
  left: 12px;
}

.line-4-1 {
  height: 31px;
  width: 50px;
  position: absolute;
  top: 11px;
  left: 4px;
}

.star-22-2 {
  height: 205px;
  width: 145px;
  position: absolute;
  top: 0;
  left: 28px;
}

.star-23-1 {
  height: 107px;
  width: 78px;
  position: absolute;
  top: -10px;
  left: -10px;
}

.stars-right {
  height: 185px;
  width: 153px;
  align-self: flex-end;
  position: relative;
}

.star-24-2 {
  height: 151px;
  width: 112px;
  position: absolute;
  top: 34px;
  left: 0;
}

.line-container-7 {
  height: 41px;
  width: 41px;
  position: absolute;
  top: 0;
  left: 112px;
}

.headline-text {
  text-align: center;
  width: 70%;
  letter-spacing: -2.5px;
  margin-top: 20px;
  line-height: 89px;
}

#unlock-the-potential {
  font-size: clamp(45px, 4vw + 15px, 65px);
  line-height: clamp(60px, 6vw + 26px, 89px);
}

.home .subheading-text {
  letter-spacing: 0;
  text-align: center;
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  margin-top: 45px;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}

#automated-short-form {
  padding: 0 10%;
  font-size: clamp(15px, 1.5vw, 17px);
  line-height: 25px;
}

.overlap-group7-2 {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
  display: flex;
}

.ad-magic-1 {
  white-space: nowrap;
  color: var(--orchid-white);
  line-height: 70px;
  font-family: var(--font-family-montserrat);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  top: 0;
}

#ad-magic-logo {
  font-size: clamp(18px, 2.3vw, 22px);
}

.icon-label-1 {
  height: 34px;
  margin: auto;
  display: block;
}

.overlap-group6-2 {
  background-color: var(--buttery-white);
  height: 54px;
  min-width: 421px;
  border-radius: 50px;
  align-items: flex-end;
  margin-top: 19px;
  margin-left: 26.59px;
  padding: 10.6px 28px;
  display: flex;
}

.go-2 {
  height: 68px;
  width: 286px;
  margin-top: 0;
  margin-left: 27.59px;
}

.go-button {
  height: 60px;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  background-color: #3a2b19;
  border: none;
  border-radius: 50px;
  margin-top: 10px;
  margin-left: 0;
  padding: 0 50px;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  transition: all .3s ease-in-out;
}

.go-button:hover {
  cursor: pointer;
  background-color: #3a2b19;
  border-radius: 50px;
  transition: all .3s ease-in-out;
  box-shadow: 0 0 10px #b65840;
}

.early-access {
  height: 60px;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  background: linear-gradient(90deg, #b65840 0%, #f9b384 100%);
  border: none;
  border-radius: 50px;
  margin-bottom: 50px;
  margin-left: 0;
  padding: 0 50px;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  transition: all .3s ease-in-out;
}

.early-access:hover {
  cursor: pointer;
  border-radius: 50px;
  transition: all .3s ease-in-out;
  box-shadow: 0 0 10px #b65840;
}

.go-button:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background-color: #3a2b194f;
  border-radius: 50px;
}

.login {
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 20px 10px 0 0;
  display: flex;
}

p.login-text {
  letter-spacing: 0;
  padding: 0 30px;
  font-size: 14px;
  line-height: 30px;
}

a.early-access-text {
  letter-spacing: 0;
  color: #c85b3d;
  margin-bottom: 70px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
}

.login-pic {
  height: 50px;
  width: 105px;
  margin-bottom: -7.96px;
}

.second-section {
  height: auto;
  width: 100%;
  overflow: wrap;
  background-color: #fff6e3;
  flex-direction: column;
  place-content: center;
  padding-top: 20px;
  padding-bottom: 70px;
  display: flex;
  position: relative;
}

.heart-bullets-1 {
  min-height: 250px;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 12px;
  display: flex;
  position: relative;
}

.our-comprehensive-da-1 {
  letter-spacing: -1px;
  width: 1153px;
  text-align: left;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 30px;
  line-height: 40px;
  position: relative;
  top: 20px;
}

.learn-your-audience-1 {
  letter-spacing: -2px;
  text-align: left;
  width: 1153px;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 20px;
  line-height: 65px;
  position: relative;
  top: 20px;
}

#our-comprehensive {
  font-size: 20px;
}

.graphic-1 {
  background-color: var(--cognac);
  height: 260px;
  min-width: 558px;
  z-index: 1;
  border-radius: 50px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  padding: 15.1px 10.4px;
  display: flex;
  position: relative;
}

.flex-col-10 {
  min-height: 80px;
  width: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  margin-bottom: 9.73px;
  display: flex;
}

.ellipse-8-1 {
  background-color: var(--soft-amber);
  height: 5px;
  width: 5px;
  border-radius: 2.39px / 2.65px;
}

.rectangle-142-1 {
  background-color: var(--black-4);
  height: 66px;
  width: 5px;
  border-radius: 31px;
}

.overlap-group-20 {
  height: 229px;
  min-width: 471px;
  background-size: 100% 100%;
  justify-content: flex-end;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: 14px;
  padding: 78.6px 204.1px;
  display: flex;
}

.polygon-1-1 {
  height: 51px;
  width: 42px;
}

.ellipse-9-1 {
  background-color: var(--soft-amber);
  height: 31px;
  width: 28px;
  border-radius: 13.97px / 15.48px;
  margin-bottom: .88px;
  margin-left: 9px;
}

.stars-3 {
  height: 298px;
  width: 100%;
  z-index: 0;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 30px;
}

.overlap-group4-7 {
  height: 236px;
  width: 160px;
  position: relative;
}

.line-container-8 {
  height: 65px;
  width: 65px;
  position: absolute;
  top: 0;
  left: 95px;
}

.star-22-3 {
  height: 202px;
  width: 112px;
  position: absolute;
  top: 34px;
  left: 0;
}

.line-container-9 {
  height: 32px;
  width: 32px;
  align-self: center;
  margin-top: 90px;
  margin-left: -30px;
  position: absolute;
}

.overlap-group3-9 {
  height: 238px;
  width: 115px;
  align-self: flex-end;
  position: absolute;
  right: 0;
}

.section-middle {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-middle-text {
  width: 100%;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-left: 200px;
  padding-right: 200px;
  display: flex;
}

.section-middle-graphic {
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-top: 25px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}

.phone-graphic {
  width: 100%;
  max-width: 650px;
  margin-bottom: 10px;
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 40px;
}

.star-24-3 {
  height: 185px;
  width: 80px;
  position: absolute;
  top: 60px;
  right: 0;
}

.line-container-10 {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.line-6-8 {
  height: 53px;
  width: 33px;
  position: absolute;
  top: 2px;
  left: 14px;
}

.line-1-10 {
  height: 60px;
  width: 3px;
  position: absolute;
  top: 0;
  left: 29px;
}

.line-2-10 {
  height: 3px;
  width: 60px;
  position: absolute;
  top: 27px;
  left: 0;
}

.line-3-5 {
  height: 33px;
  width: 53px;
  position: absolute;
  top: 12px;
  left: 3px;
}

.line-5-9 {
  height: 53px;
  width: 33px;
  position: absolute;
  top: 3px;
  left: 13px;
}

.line-4-5 {
  height: 33px;
  width: 53px;
  position: absolute;
  top: 12px;
  left: 4px;
}

.centered-bottom {
  width: 1000px;
  height: 100%;
  flex-flow: column wrap;
  align-items: center;
  display: flex;
}

div.section-bottom-1 {
  gap: 0;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.contact-1 {
  min-height: 112px;
  width: 333px;
  flex-direction: column;
  align-items: flex-start;
  gap: -10px;
  margin-left: 92px;
  display: flex;
  position: relative;
}

.place-5 {
  letter-spacing: 0;
  min-height: 48px;
  white-space: nowrap;
  line-height: 70px;
}

.admagiccogmailcom-1 {
  cursor: pointer;
  letter-spacing: 0;
  min-height: 52px;
  width: 329px;
  line-height: 16px;
  text-decoration: underline;
}

.copyright-1 {
  letter-spacing: 0;
  text-align: right;
  width: 164px;
  margin-top: 40px;
  line-height: 16px;
  position: absolute;
  top: 26px;
  left: 0;
}

.home .go-button-div {
  margin-top: 30px;
}

#my-account-button {
  box-sizing: border-box;
  z-index: 9999;
  background: #5d2a1d61;
  border: 3.5px solid #fff;
  border-radius: 23px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 7px 15px;
  transition: all .2s ease-in-out;
  display: flex;
  position: fixed;
  top: 17px;
  right: 18px;
}

#my-account-button img {
  max-width: 100%;
  height: auto;
  filter: invert();
}

#my-account-button label {
  color: #fff;
  font-family: var(--font-family-montserrat);
  mix-blend-mode: lighten;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 600;
}

#my-account-button:hover {
  cursor: pointer;
  transition: all .2s ease-in-out;
  transform: scale(1.02);
  box-shadow: 0 0 12px #00000026;
}

#my-account-button label:hover {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  #automated-short-form {
    margin-top: 25px;
  }

  .go-button {
    height: 52px;
    margin-top: 5px;
    padding: 0 40px;
    font-size: 19px;
  }

  .stars {
    display: none;
  }

  p.login-text {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 800px) {
  .stars-3 {
    display: none;
  }

  .section-middle-text {
    padding-left: 70px;
    padding-right: 70px;
  }

  #learn-your-audience {
    padding-top: 0;
    font-size: 38px;
    line-height: 55px;
  }

  #our-comprehensive {
    font-size: 17px;
    line-height: 35px;
  }
}

@media screen and (max-width: 500px) {
  .section-middle-text {
    padding-left: 50px;
    padding-right: 50px;
  }

  .second-section {
    padding-bottom: 30px;
  }
}

.first-section::-webkit-scrollbar {
  width: 0;
  display: none;
}

.first-section {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  box-sizing: border-box;
  background: linear-gradient(#ffb673 0%, #ffd3c6 100%);
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 5vh;
  display: flex;
  position: relative;
  top: 0;
}

.first-section.frame-1-1 {
  align-items: center;
}

.custom-shape-divider-bottom-1675442855 {
  width: 100%;
  line-height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: visible;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1675442855 svg {
  z-index: -1;
  width: calc(100% + 1.3px);
  height: 84px;
  display: block;
  position: relative;
}

.custom-shape-divider-bottom-1675442855 .shape-fill {
  fill: #fff6e3;
}

.grouped-content {
  min-height: 735px;
  width: 100%;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.shapedividers_com-8793 {
  position: relative;
  overflow: visible;
}

.shapedividers_com-8793:before {
  content: "";
  z-index: 3;
  pointer-events: none;
  background-image: url("data:image/svg+xml;charset=utf8, <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 35.28 2.17\" preserveAspectRatio=\"none\"><path d=\"M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z\" fill=\"%23fff6e3\"/></svg>");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100% 92px;
  font-family: shape divider from ShapeDividers\.com;
  position: absolute;
  inset: -.1vw;
}

@media (min-width: 2100px) {
  .shapedividers_com-8793:before {
    background-size: 100% calc(2vw + 92px);
  }
}



:root {
  --alto: #d9d9d9;
  --black: #000;
  --black-2: #000000eb;
  --black-3: #00000040;
  --black-4: #0300001f;
  --black-marlin: #3a2b19;
  --blue-violet: #5b59c2;
  --buttery-white: #fff9ebd1;
  --cognac: #9d3c1326;
  --emperor: #52463f;
  --eternity: #261c10;
  --gravel: #4b4b4b;
  --gray: #9e9191;
  --gray-nurse: #e9e9e9d1;
  --heavy-metal: #2c2828;
  --orchid-white: #fffbf2;
  --pumice: #cacaca;
  --sazerac: #fff5e3;
  --serenade: #fff7e9;
  --soft-amber: #d4c3b4;
  --thunder: #312a2a;
  --white: #fff;
  --white-2: #ffffffdb;
  --white-3: #ffffffde;
  --white-rock: #ece7dbd1;
  --font-size-l: 14px;
  --font-size-m: 13px;
  --font-size-s: 12px;
  --font-size-xl: 15px;
  --font-size-xxl: 17px;
  --font-size-xxxl: 18px;
  --font-size-xxxxl: 20px;
  --font-family-montserrat: "Montserrat", Helvetica;
  --font-family-source_sans_3: "Source Sans 3", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
}

.montserrat-normal-black-15px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-heavy-metal-13px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-serenade-13px {
  color: var(--serenade);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-medium-black-12px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.sourcesans3-normal-black-marlin-20px {
  color: var(--black-marlin);
  font-family: var(--font-family-source_sans_3);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-bold-serenade-14px {
  color: var(--serenade);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 800;
}

.montserrat-semi-bold-black-25px {
  color: var(--black-2);
  font-family: var(--font-family-montserrat);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-bold-heavy-metal-14px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-heavy-metal-14px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-heavy-metal-13px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-heavy-metal-12px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.montserrat-light-gravel-17px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 300;
}

.montserrat-light-gravel-16px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.montserrat-bold-heavy-metal-17px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-black-35px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-serenade-14px {
  color: var(--serenade);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-light-gravel-18px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 200;
}

.montserrat-bold-white-21px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-heavy-metal-12px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-light-gravel-13px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 200;
}

.montserrat-bold-orchid-white-25px {
  color: var(--orchid-white);
  font-family: var(--font-family-montserrat);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-orchid-white-22px {
  color: var(--orchid-white);
  font-family: var(--font-family-montserrat);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-extra-bold-black-65px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 65px;
  font-style: normal;
  font-weight: 800;
}

.montserrat-normal-heavy-metal-18px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-heavy-metal-17px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-black-20px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-eternity-43px {
  color: var(--eternity);
  font-family: var(--font-family-montserrat);
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-black-40px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-black-17px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-semi-bold-black-15px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.montserrat-bold-pumice-20px {
  color: var(--pumice);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-extra-light-gravel-16px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
}

.montserrat-bold-cream-14px {
  color: #fff8ea;
  font-family: var(--font-family-montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
}

.montserrat-normal-cream-13px {
  color: #fff8ea;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.montserrat-normal-cream-14px {
  color: #fff8ea;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

/*# sourceMappingURL=index.0b66f24b.css.map */
