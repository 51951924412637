.first-section::-webkit-scrollbar {
  display: none;
  width: 0;
}

.first-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  top: 0;
  width: 100%;
  align-items: center;
  height: fit-content;
  background: linear-gradient(
    180deg,
    rgb(255, 182.07, 114.75) 0%,
    rgb(255, 211.39, 197.62) 100%
  );
  box-sizing: border-box;
  padding-bottom: 5vh;
}

.first-section.frame-1-1 {
  align-items: center;
}

.custom-shape-divider-bottom-1675442855 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: visible;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1675442855 svg {
  position: relative;
  display: block;
  z-index: -1;
  width: calc(100% + 1.3px);
  height: 84px;
}

.custom-shape-divider-bottom-1675442855 .shape-fill {
  fill: #fff6e3;
}

.grouped-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 735px;
  position: relative;
  width: 100%;
  justify-content: center;
  z-index: 2;
}

.shapedividers_com-8793 {
  overflow: visible;
  position: relative;
}
.shapedividers_com-8793::before {
  content: "";
  font-family: "shape divider from ShapeDividers.com";
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw;
  background-size: 100% 92px;
  background-position: 50% 100%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" fill="%23fff6e3"/></svg>');
}

@media (min-width: 2100px) {
  .shapedividers_com-8793::before {
    background-size: 100% calc(2vw + 92px);
  }
}
