:root {
  --alto: #d9d9d9;
  --black: #000000;
  --black-2: #000000eb;
  --black-3: #00000040;
  --black-4: #0300001f;
  --black-marlin: #3a2b19;
  --blue-violet: #5b59c2;
  --buttery-white: #fff9ebd1;
  --cognac: #9d3c1326;
  --emperor: #52463f;
  --eternity: #261c10;
  --gravel: #4b4b4b;
  --gray: #9e9191;
  --gray-nurse: #e9e9e9d1;
  --heavy-metal: #2c2828;
  --orchid-white: #fffbf2;
  --pumice: #cacaca;
  --sazerac: #fff5e3;
  --serenade: #fff7e9;
  --soft-amber: #d4c3b4;
  --thunder: #312a2a;
  --white: #ffffff;
  --white-2: #ffffffdb;
  --white-3: #ffffffde;
  --white-rock: #ece7dbd1;

  --font-size-l: 14px;
  --font-size-m: 13px;
  --font-size-s: 12px;
  --font-size-xl: 15px;
  --font-size-xxl: 17px;
  --font-size-xxxl: 18px;
  --font-size-xxxxl: 20px;

  --font-family-montserrat: "Montserrat", Helvetica;
  --font-family-source_sans_3: "Source Sans 3", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
}
.montserrat-normal-black-15px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-heavy-metal-13px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-serenade-13px {
  color: var(--serenade);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-medium-black-12px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.sourcesans3-normal-black-marlin-20px {
  color: var(--black-marlin);
  font-family: var(--font-family-source_sans_3);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-bold-serenade-14px {
  color: var(--serenade);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 800;
}

.montserrat-semi-bold-black-25px {
  color: var(--black-2);
  font-family: var(--font-family-montserrat);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-bold-heavy-metal-14px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-heavy-metal-14px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-heavy-metal-13px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-heavy-metal-12px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.montserrat-light-gravel-17px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 300;
}

.montserrat-light-gravel-16px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.montserrat-bold-heavy-metal-17px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-black-35px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-serenade-14px {
  color: var(--serenade);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-light-gravel-18px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 200;
}

.montserrat-bold-white-21px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-heavy-metal-12px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-light-gravel-13px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 200;
}

.montserrat-bold-orchid-white-25px {
  color: var(--orchid-white);
  font-family: var(--font-family-montserrat);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-orchid-white-22px {
  color: var(--orchid-white);
  font-family: var(--font-family-montserrat);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-extra-bold-black-65px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 65px;
  font-style: normal;
  font-weight: 800;
}

.montserrat-normal-heavy-metal-18px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-heavy-metal-17px {
  color: var(--heavy-metal);
  font-family: var(--font-family-montserrat);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-black-20px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-eternity-43px {
  color: var(--eternity);
  font-family: var(--font-family-montserrat);
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-black-40px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-black-17px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-semi-bold-black-15px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.montserrat-bold-pumice-20px {
  color: var(--pumice);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-extra-light-gravel-16px {
  color: var(--gravel);
  font-family: var(--font-family-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
}

.montserrat-bold-cream-14px {
  color: #fff8ea;
  font-family: var(--font-family-montserrat);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
}

.montserrat-normal-cream-13px {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #fff8ea;
}

.montserrat-normal-cream-14px {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff8ea;
}
