.heart-1-1 {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 15px;
  line-height: 55px;
}

.icon-star {
  margin-top: 15px;
}

div.bullet-text {
  font-size: 18px;
  font-family: var(--font-family-montserrat);
  font-weight: 400;
  color: #2d2a28;
}

@media screen and (max-width: 800px) {
  div.bullet-text {
    line-height: 43px;
    font-size: 16.5px;
  }
  .icon-star {
    width: 18px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 507px) {
  div.bullet-text {
    line-height: 30px;
  }
  .icon-star {
    margin-top: 3px;
  }
}
