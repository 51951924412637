@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,300,500,600,800,200|Source+Sans+3:400|Poppins:400,700,900,500,600");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/6275b9721203600aeca6fe44.6275b9746bea9bff3434837c.OHhrErV.rcp.png");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap");

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-color: #fff8ea;
  z-index: 1;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}
