.star-26 {
  align-items: flex-start;
  display: flex;
  height: 25px;
  left: 29px;
  min-width: 25px;
  padding: 0 0.6px;
  position: absolute;
  top: 7px;
  filter: brightness(0) saturate(100%) invert(94%) sepia(12%) saturate(676%)
    hue-rotate(327deg) brightness(86%) contrast(90%);
}

.icon-star {
  height: 24px;
  width: 24px;
}

.star-26.star-27 {
  height: 45px;
  left: 20px;
  min-width: 43px;
  padding: 0 1.1px;
  top: -2px;
}

.star-26.star-27 .icon-star {
  height: 41px;
  width: 41px;
}

.star-26.star-28 {
  height: 34px;
  left: unset;
  margin-right: 14.38px;
  min-width: 34px;
  padding: 0px 0.8px;
  position: unset;
  top: unset;
}

.star-26.star-28 .icon-star {
  height: 31px;
  width: 32px;
}
